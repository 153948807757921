import { Button, Box, Container, useTheme, Dialog } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby-theme-material-ui";
import { useState } from "react";
import styled from "styled-components";
import { ContactForm } from "../components/ContactFormDescription";

const Contact = styled(Box)``; // referenced as a child

const HeroWrapper = styled(Container)`
  ${({ theme }) => `
    ${Contact} {
        text-align:center;
    }
    @media screen and (min-width:${theme.breakpoints.values.md}px){
        ${Contact} {
            text-align:left;
        }
    }
    &#homepage-hero .hero-text img {
      width:80%;
    }
  `}
`;

const HeroNav = styled.ul`
  ${({ theme }) => `
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-bottom: ${theme.spacing(1)};
    justify-content:center;
    @media screen and (min-width:${theme.breakpoints.values.md}px){
        justify-content:flex-start;
    }
  `}
`;

const HeroNavItem = styled.li`
  ${({ theme }) => `
  border-right: 3px solid ${theme.palette.secondary.main};
  padding-right: ${theme.spacing(2)};
  margin-right: ${theme.spacing(2)};
  font-weight: 600;
  letter-spacing: 0.05em;
  position: relative;
  &:last-child {
    border-right: 0;
    padding-right: 0;
  }
`}
`;

const Hero = ({
  description: {
    childMarkdownRemark: { html: description },
  },
  image,
  placement,
  contentfulid,
  backgroundColor,
}) => {
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const openContactForm = () => {
    window.dataLayer?.push({
      event: `contact_form_open`,
      page: window.location.href,
      element: `hero_section`,
    });
    setContactFormVisible(true);
  };
  const theme = useTheme();
  const {
    site: {
      siteMetadata: { title },
    },
    contentfulNavigation: { items },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        contentfulNavigation(contentful_id: { eq: "1LpgVAw62akDLLzZWCqmHf" }) {
          items {
            title
            url
            page {
              path
            }
          }
        }
      }
    `
  );
  return (
    <>
      <Dialog
        open={contactFormVisible}
        onClose={() => setContactFormVisible(false)}
        maxWidth="md"
      >
        <Container>
          <Box p={1}>
            <ContactForm heading={`Contact Andrew Rhatigan`} />
          </Box>
        </Container>
      </Dialog>
      <Box
        bgcolor={
          backgroundColor === `Secondary`
            ? theme.palette.primary.light
            : `transparent`
        }
      >
        <HeroWrapper id={contentfulid} maxWidth="lg">
          <Box
            display="flex"
            margin={{ sm: `auto` }}
            maxWidth={{ xs: 558, md: `100%` }}
            flexDirection={{ xs: `column`, md: `row` }}
            alignItems="center"
            justifyContent={{ sm: `center` }}
          >
            <Box
              order={{ md: placement === `Text on Left` ? 2 : 1 }}
              flex="1"
              display="flex"
            >
              <img
                src={image?.fixed?.src || image?.file?.url || ``}
                alt={title}
                style={{ margin: `auto` }}
              />
            </Box>
            <Box
              flex="1"
              order={{ sm: placement === `Text on Left` ? 1 : 2 }}
              py={{ md: 3, xs: 1 }}
              paddingLeft={{ md: placement === `Text on Right` && 2 }}
              paddingRight={{ md: placement === `Text on Left` && 2 }}
              className="hero-text"
            >
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
              {contentfulid === `homepage-hero` && (
                <>
                  {items && (
                    <HeroNav>
                      {items.map(({ page, url, title }, index) => (
                        <HeroNavItem key={`hero-nav-${index}`}>
                          <Link to={page?.path || url}>{title}</Link>
                        </HeroNavItem>
                      ))}
                    </HeroNav>
                  )}
                  <Contact mt={3}>
                    <Button
                      color="secondary"
                      id="open-contact-form-button"
                      onClick={openContactForm}
                    >
                      Contact Andrew
                    </Button>
                  </Contact>
                </>
              )}
            </Box>
          </Box>
        </HeroWrapper>
      </Box>
    </>
  );
};

export default Hero;
