import { Box, Container, Typography } from "@material-ui/core";
import documentToHtmlString from "../util/documentToHtmlString";

const TextSection = ({ heading, description, backgroundColor }) => {
  const descriptionHTML = documentToHtmlString(JSON.parse(description?.raw));
  return (
    <Box bgcolor={backgroundColor ? `primary.light` : ``}>
      <Container>
        <Box py={2}>
          {heading && <Typography variant="h2">{heading}</Typography>}
          <Typography component="div" variant="body2">
            <div
              dangerouslySetInnerHTML={{
                __html: descriptionHTML,
              }}
            />
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default TextSection;
