import { Box, Container, Hidden, Typography } from "@material-ui/core";
import styled from "styled-components";

const Description = styled(Box)`
  font-style: italic;
`;

const Testimonial = ({
  heading,
  name,
  title,
  image,
  testimonial: {
    childMarkdownRemark: { html: description },
  },
}) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: `column`, md: `row` }}
      justifyContent={{ md: `space-between` }}
      alignItems="center"
      textAlign={{ xs: `center`, md: `left` }}
    >
      <Box
        mb={{ xs: 1, md: 0 }}
        width="100%"
        maxWidth={{ xs: 400, md: 400 }}
        justifyContent="center"
        display="flex"
      >
        <img src={image?.file?.url || ``} alt={name} width="100%" />
      </Box>
      <Box flex={1} ml={{ md: 2 }} maxWidth={{ md: 580, xs: `100%` }}>
        <Hidden xsDown>
          <Typography variant="h2">{heading}</Typography>
        </Hidden>
        <Description mb={2} dangerouslySetInnerHTML={{ __html: description }} />
        <Box mb={2}>
          <Typography variant="h3">{name}</Typography>
          <Typography>{title}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonial;
