import {
  Box,
  Container,
  Grid,
  Icon as MuiIcon,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

const IconWrapper = styled(`div`)`
  background: linear-gradient(
    90deg,
    rgba(0, 117, 186, 1) 35%,
    rgba(0, 82, 148, 1) 100%
  );
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  .material-icons {
    font-size: 32px;
  }
`;

const IconTile = ({ description, title, icon, muiIconName }) => {
  const descriptionText = description?.childMarkdownRemark?.html;
  return (
    <Box display="flex" flexDirection="column">
      <Box mb={1}>
        {muiIconName ? (
          <IconWrapper>
            <MuiIcon fontSize="large">{muiIconName}</MuiIcon>
          </IconWrapper>
        ) : (
          <img width={53} src={icon?.fixed?.src} alt={title} />
        )}
      </Box>
      <Box>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        {descriptionText && (
          <Box component="div">
            <div dangerouslySetInnerHTML={{ __html: descriptionText }}></div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const IconTileSection = ({ iconTiles }) => {
  return (
    <Container>
      <Box py={2}>
        <Grid container>
          {iconTiles.map(({ id, ...tile }) => (
            <Grid item sm={6} key={id}>
              <Box pb={2}>
                <IconTile {...tile} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default IconTileSection;
