import * as components from "../components";
import Layout from "../components/Layout";

/* Styles */
import { graphql } from "gatsby";

const PageTemplate = ({
  data: {
    contentfulPage: {
      blocks: sections,
      title,
      description,
      backgroundImage,
      metaDescription,
    },
  },
}) => {
  return (
    <Layout
      title={title}
      backgroundImage={backgroundImage?.fixed?.src}
      description={description}
      metaDescription={metaDescription}
    >
      {sections &&
        sections.map((section, index) => {
          const componentName = section.sys.contentType.sys.id;
          const componentNameCapitalized =
            componentName[0].toUpperCase() + componentName.slice(1);
          const Component = components[componentNameCapitalized];
          if (Component) {
            return (
              <Component
                id={section.id}
                key={`section-${index}`}
                {...section}
              />
            );
          }
          console.warn(
            `Warning: Cannot find component with name '${componentNameCapitalized}'. Did you forget to export it from ./components/index.js?`
          );
          return null;
        })}
    </Layout>
  );
};

export default PageTemplate;
export const PageQuery = graphql`
  query Page($id: String) {
    contentfulPage(id: { eq: $id }) {
      id
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      backgroundImage {
        fixed(
          quality: 95
          width: 1400
          height: 400
          resizingBehavior: FILL
          cropFocus: FACES
          toFormat: JPG
        ) {
          src
        }
      }
      blocks {
        ... on Node {
          ... on ContentfulCommunityGuideSection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulListingSection {
            filter {
              type
            }
            heading
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulHeroSectionSideBySide {
            id
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              file {
                url
              }
              fixed(quality: 100, width: 700) {
                src
              }
            }
            placement
            contentfulid
            backgroundColor
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTextSection {
            id
            heading
            backgroundColor
            description {
              raw
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulIconTileSection {
            id
            iconTiles {
              id
              description {
                childMarkdownRemark {
                  html
                }
              }
              title
              muiIconName
              icon {
                fixed(quality: 100, width: 64) {
                  src
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTestimonialsSection {
            id
            title
            heading
            testimonials {
              title
              id
              name
              image {
                file: fixed(
                  height: 300
                  width: 289
                  resizingBehavior: FILL
                  cropFocus: CENTER
                ) {
                  url: src
                }
              }
              testimonial {
                childMarkdownRemark {
                  html
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulContactFormWithDescription {
            id
            heading
            defaultValue
            description {
              childMarkdownRemark {
                html
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulContactForm {
            id
            heading
            defaultValue
            description {
              childMarkdownRemark {
                html
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
