import { Container, Typography, Box, Hidden } from "@material-ui/core"
import Testimonial from "./Testimonial"
import Carousel from "./Carousel"

const TestimonialsSection = ({ heading, testimonials }) => {
  return (
    <Box
      css={{
        background: `url(/images/circles-background.png);`,
        backgroundPosition: `0 top`,
      }}
      pt={4}
      textAlign={{ xs: `center`, sm: `left` }}
      paddingBottom={{ xs: 4 }}
    >
      <Container
        disableGutters
        style={{ paddingLeft: `50px`, paddingRight: `50px` }}
      >
        <Hidden smUp>
          <Typography variant="h2">{heading}</Typography>
        </Hidden>
        <Carousel adaptiveHeight>
          {testimonials.map(({ id, ...testimonial }) => {
            return <Testimonial key={id} heading={heading} {...testimonial} />
          })}
        </Carousel>
      </Container>
    </Box>
  )
}

export default TestimonialsSection
