import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Map } from "../../static/images/map.svg";
import { isEmpty } from "lodash";
import slugify from "slugify";
import theme from "../config/theme";
import documentToHtmlString from "../util/documentToHtmlString";

const CommunityGuide = ({ title, description = `` }) => {
  const {
    towns: { nodes: towns },
  } = useStaticQuery(graphql`
    query CommunityGuide {
      towns: allContentfulNeighborhoodGuideTown(sort: { fields: name }) {
        nodes {
          name
          description {
            raw
          }
          listing {
            id
            active
          }
          places: neighborhood_guide__place {
            name
            id
            category
          }
        }
      }
    }
  `);
  const [town, setTown] = useState({});
  const townLink = useRef();
  // const TownCategories = () => {
  //     if(!town?.places){
  //         return null
  //     }
  //     return town.places.reduce((cats, {id, category}) => {
  //         if(!category){
  //             return cats
  //         }
  //         category.forEach(cat => {
  //             if(!cats.includes(cat)){
  //                 cats.push(cat)
  //             }
  //         })
  //         return cats
  //     }, []).map((c,i) => (
  //         <Box key={`cat-${i}`}>
  //             <Typography key={`cat-${i}`}>{c}</Typography>
  //         </Box>
  //     ))
  // }
  const handleMouseOver = function (e) {
    const id = e.currentTarget.id;
    if (!id) {
      return;
    }
    const town = towns.find(
      (t) => t.name.toLowerCase() === id.replace(`_`, ` `)
    );
    document
      .getElementById(`map`)
      .querySelectorAll(`.hovered`)
      .forEach((el) => el.classList.remove(`hovered`));
    document.getElementById(id)?.classList.add(`hovered`);
    setTown(town || { name: id.replace(`_`, ` `) });
  };
  const handleClick = (e) => {
    if (window.innerWidth > theme.breakpoints.values.sm) {
      townLink.current && townLink.current.click();
    } else {
      handleMouseOver(e);
    }
  };
  const reset = () => {
    setTown({});
    document
      .querySelector(`#map`)
      .querySelector(`.hovered`)
      .classList.remove(`hovered`);
  };
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const children = Array.prototype.filter.call(
        document.querySelector(`#map`).children,
        function (el) {
          return el.matches(`g`);
        }
      );
      children.forEach((el) => {
        el.addEventListener(`mouseover`, handleMouseOver);
        el.addEventListener(`click`, handleClick);
      });
      document.querySelector(`#map`).addEventListener(`mouseout`, reset);
      return () => {
        document.querySelector(`#map`).removeEventListener(`mouseout`, reset);
        children.forEach((el) => {
          el.removeEventListener(`mouseover`, handleMouseOver);
          el.removeEventListener(`click`, handleClick);
        });
      };
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  return (
    <Container>
      <Box py={3}>
        <Grid container alignItems="flex-start" justify="center">
          <Grid item xs={12} md={6}>
            <Box maxWidth={{ xs: 400, md: `100%` }} margin="auto">
              <Map />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              pt={{ md: 10 }}
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              textAlign="center"
            >
              <Hidden mdUp>
                <Typography variant="h2">{title}</Typography>
              </Hidden>
              {town?.name && (
                <Hidden smDown>
                  <Typography variant="overline">{title}</Typography>
                </Hidden>
              )}
              <Hidden smDown>
                <Typography variant="h2">{town?.name || title}</Typography>
              </Hidden>
              <Hidden mdUp>
                <Box>
                  <FormControl label="Select Town">
                    <InputLabel htmlFor="town">Select Town</InputLabel>
                    <Select
                      name="town"
                      value={town?.name || ``}
                      onChange={(e) => {
                        e.target.id = e.target.value;
                        //setTown(towns.find((t) => t.name === e.target.value))
                        handleMouseOver({
                          currentTarget: {
                            id: e.target.value.toLowerCase().replace(` `, `_`),
                          },
                        });
                      }}
                      label="Select Town"
                      placeholder="Select Town"
                    >
                      {towns.map(({ id, name }, i) => (
                        <MenuItem key={`town-option-${i}`} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Hidden>
              <Box display="flex" flexDirection="column">
                {isEmpty(town) && description && (
                  <Box margin="auto" textAlign="left" maxWidth={400}>
                    <Typography variant="body2" component="div">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description.childMarkdownRemark.html,
                        }}
                      />
                    </Typography>
                  </Box>
                )}
                {town && (
                  <>
                    {town.description && (
                      <Box margin="auto" textAlign="left" maxWidth={400}>
                        <Typography variant="body2" component="div">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                documentToHtmlString(
                                  JSON.parse(town.description?.raw)
                                ) +
                                (Array.isArray(town.listing) &&
                                town.listing.filter((l) => l.active).length > 0
                                  ? `Listings in area.`
                                  : ``),
                            }}
                          />
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      {town.description && (
                        <Button
                          component="a"
                          ref={townLink}
                          href={`/community-guide/${slugify(town.name, {
                            lower: true,
                          })}`}
                        >
                          Learn More
                        </Button>
                      )}
                    </Box>
                    {/* <TownCategories /> */}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default CommunityGuide;
